











import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    title: {
      type: String,
    },
  },
  setup(props, { root }) {
    return {};
  },
});
