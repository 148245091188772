




















import { defineComponent, onMounted } from '@vue/composition-api';
import { useDemandStore } from './store';
import { Demand, getDemandDetail } from './api';
import DetailCard from '@/views/Result/DetailCard.vue';
import Descriptions from '@/components/Descriptions.vue';
import { useApi } from '@/util/useApi';

export default defineComponent({
  components: { DetailCard, Descriptions },
  setup(props, { root }) {
    // const { state } = useMainStore();
    const { result: detail, refresh } = useApi((id: number) => getDemandDetail(id), null, false);

    onMounted(() => {
      if (typeof root.$route.params.id !== 'undefined') {
        refresh(Number(root.$route.params.id));
      }
    });

    return { detail };
  },
});
