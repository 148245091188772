var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    ,
    {
      'descriptions-bordered': _vm.bordered,
      descriptions: _vm.size === 'default',
      'descriptions-middle': _vm.size === 'middle',
      'descriptions-small': _vm.size === 'small',
    } ]},[(_vm.title)?_c('div',{staticClass:"descriptions-title"},[_vm._t("title",[_vm._v(_vm._s(_vm.title))])],2):_vm._e(),_c('div',{staticClass:"descriptions-view"},[_c('table',{staticClass:"descriptions-table"},[_c('tbody',_vm._l((_vm.rows),function(row,key){return _c('tr',{key:key,staticClass:"descriptions-row"},[_vm._l((row),function(item,index){return [(_vm.bordered)?[_c('th',{key:'label-' + key + '-' + index,staticClass:"descriptions-item-label"},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('td',{key:'content-' + key + '-' + index,staticClass:"descriptions-item-content",attrs:{"colSpan":item.span * 2 - 1}},[_c('description-content',{attrs:{"item":item}})],1)]:[_c('td',{key:'content-' + key + '-' + index,staticClass:"descriptions-item",attrs:{"colSpan":item.span * 2 - 1}},[_c('span',{staticClass:"descriptions-item-label"},[_vm._v(_vm._s(item.label))]),_c('span',{staticClass:"descriptions-item-content"},[_c('description-content',{attrs:{"item":item}})],1)])]]})],2)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }